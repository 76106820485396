import type { TailoredCloudinaryImageProps } from "components/ResponsiveImage";
import debounce from "lodash.debounce";
import { useRouter } from "next/router";
import { useEffect, useLayoutEffect, useRef, useState } from "react";

import utilityStyles from "../styles/utility.module.scss";
import sassVariables from "../styles/variables.module.scss";

/** Is this the client (Browser) or is it a server (Node) */
export const isClient = typeof window !== "undefined";

export const imagePlaceholder = `hardcoded-images/${process.env.NEXT_PUBLIC_SITE_THEME}/placeholder`;

export function doesNotPreferReducedMotion() {
    if (isClient && window.matchMedia) {
        return !window.matchMedia("(prefers-reduced-motion)").matches;
    } else {
        return true;
    }
}

export type PaddingValue = "NONE" | "LESS" | "NORMAL" | "SLIGHTLYMORE" | "MORE";

function getProductImagePadding(amount: PaddingValue) {
    switch (amount) {
        case "LESS":
            return 90;
        case "NORMAL":
            return 75;
        case "SLIGHTLYMORE":
            return 50;
        case "MORE":
            return 25;
        case "NONE":
            return undefined;
    }
}

export function getProductImageTransforms(
    pad: PaddingValue,
): TailoredCloudinaryImageProps {
    return {
        crop: "pad",
        aspectRatio: "1:1",
        background: "#ffffff",
        padPercent: getProductImagePadding(pad),
    };
}

let isClientCache = false;

export function useIsClient() {
    const [isClient, setIsClient] = useState(isClientCache);

    useEffect(() => {
        if (!isClient) {
            isClientCache = true;
            setIsClient(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isClient;
}

export function useWindowWidth() {
    const [width, setWidth] = useState<number | undefined>(undefined);

    useEffect(() => {
        setWidth(window.innerWidth);
        const handleUpdateWidth = debounce(
            () => setWidth(window.innerWidth),
            20,
        );
        window.addEventListener("resize", handleUpdateWidth);

        return () => window.removeEventListener("resize", handleUpdateWidth);
    }, []);

    return width;
}

/**
 * body-scroll-lock was suggested as an alternative to this; I figured let's stick with
 * the lighter solve unless it proves to be unworkable
 */
export const bodyScrollLockToggle = (condition: boolean) => {
    const body = document.querySelector("body");
    if (condition) {
        body?.classList.add(utilityStyles.overflowHidden);
    } else {
        body?.classList.remove(utilityStyles.overflowHidden);
    }
};

/**
 * Copied from https://github.com/reach/reach-ui/blob/main/packages/utils/src/can-use-dom.ts
 * You almost certainly want useIsClient instead
 */
function canUseDOM() {
    return !!(
        typeof window !== "undefined" &&
        window.document &&
        window.document.createElement
    );
}

/**
 * Copied from https://github.com/reach/reach-ui/blob/main/packages/utils/src/use-isomorphic-layout-effect.ts
 * React gives a stupid warning when using useLayoutEffect on the server instead of just making it a noop like useEffect
 * This silences the warning without causing any bugs, promise
 */
export const useIsomorphicLayoutEffect = canUseDOM()
    ? useLayoutEffect
    : useEffect;

export const useVisibleSlidesByBreakpoint = (
    defaultSlideCount: number,
    mobileSlideCount: number,
    tabletSlideCount: number,
    tinySlideCount?: number,
) => {
    const tabletBreakpoint = parseInt(sassVariables.breakpointTablet);
    const mobileBreakpoint = parseInt(sassVariables.breakpointMobile);
    const tinyBreakpoint = parseInt(sassVariables.breakpointTiny);
    const windowWidth = useWindowWidth();
    if (typeof windowWidth !== "number") return defaultSlideCount;
    if (windowWidth < tinyBreakpoint && tinySlideCount) return tinySlideCount;
    if (windowWidth < mobileBreakpoint) return mobileSlideCount;
    if (windowWidth < tabletBreakpoint) return tabletSlideCount;
    return defaultSlideCount;
};

export function filterOutNulls<T>(x: T | null | undefined): x is T {
    return !!x;
}

export const useCurrentPath = () => {
    const router = useRouter();

    /** Strip query params */
    const path = router.asPath.split("?")[0];

    return path;
};

export const truthyPathMatch = (path: string, linkUrl: string) => {
    /** Presence or lack of trailing slash shouldn't matter */
    if (linkUrl === path) return true;
    if (linkUrl === path + "/") return true;
    if (linkUrl + "/" === path) return true;
    return false;
};

export const truthyPartialPathMatch = (path: string, linkUrl: string) => {
    return path.includes(linkUrl);
};

export const formatPhoneNumber = (phoneNumberString: string): string => {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return ["(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return "";
};

export const useIsMobile = () => {
    const mobileBreakpoint = parseInt(sassVariables.breakpointMobile);
    const windowWidth = useWindowWidth();
    if (typeof windowWidth !== "number") return false;
    if (windowWidth < mobileBreakpoint) return true;
    return false;
};

export function usePreviousValue<T>(value: T) {
    const ref = useRef(value);
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}

export const slugify = (value: string): string => {
    return value
        .replace(/[^\w\s-]/g, "")
        .trim()
        .toLowerCase()
        .replace(/[-\s]+/g, "-");
};

/**
 * Array filter predicate for removing null, undefined, and empty string values from a list
 */
export const notEmptyOrBlank = <T>(
    value: T | "" | null | undefined,
): value is T => {
    return value !== "" && value !== null && value !== undefined;
};

export const startsWithVowel = (word: string): boolean => {
    const vowels = "aeiouAEIOU";
    return vowels.indexOf(word[0]) !== -1;
};
